@import "./components/index.scss";
@import "./pages/index.scss";
@import "./global.scss";

.md-editor {
  .cm-content {
    flex-shrink: unset !important;
    .cm-line {
      white-space: pre-wrap;
    }
  }
}
