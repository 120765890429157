.view-all-task-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  padding: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .text {
      font-size: 24px;
      font-weight: 600;
      color: var(--neutral-mid-gray-600);
    }
  }

  .tasks-grid-wrapper {
    flex: 1;
    .crm-ant-text-com {
      font-size: 12px;
      font-weight: inherit;
      color: inherit;
      margin-left: 8px;
    }
  }
}
