.follow-up-page-wrapper {
  width: 100%;

  .title-wrapper {
    font-size: 24px;
    font-weight: 600;
    color: var(--neutral-mid-gray-600);
  }

  .regular-follow-up-data {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .regular-follow-up-data-wrapper {
      max-width: 150px;
      width: max-content;

      .top {
        background: var(--unique-color-purple-300);
        padding: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
      }

      .bottom {
        border: 2px solid var(--unique-color-purple-300);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 10px;
        text-align: center;
        font-size: 50px;
        font-weight: 700;
        color: var(--unique-color-purple-300);
      }
    }
  }

  .follow-up-data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    padding: 24px;
    border-radius: 10px;
    margin: 12px 0px;

    .left {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
    }

    .right {
      width: max-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;

      .task-progress-title {
        font-size: 16px;
        font-weight: 700;
        color: var(--neutral-mid-gray-600);
      }

      .task-progress {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        .text {
          font-size: 14px;
          font-weight: 700;
        }

        .task-text {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  .hr-line {
    margin: 30px 0;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
  }
}
