/* Accordion container */

/* Accordion container */
.feedback-tree-view-accordion {
  border: 1px solid #e0e0e0;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  /* Accordion header */
  .accordion-header {
    background-color: #3498db;
    padding: 15px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #fff;
    border-bottom: 2px solid #2980b9;
  }

  /* Arrow icon */
  .accordion-header .arrow {
    font-size: 20px;
    transition: transform 0.3s ease-in-out;
  }

  /* Background color for expanded state */
  .expanded .accordion-header {
    background-color: #2980b9;
  }

  /* Accordion content */
  .accordion-content {
    padding: 15px;
    background-color: #fff;
    border-top: 2px solid #e0e0e0;
  }

  /* Accordion items */
  .accordion-item {
    margin-bottom: 10px;
    color: #333;
  }

  /* Style for the nested accordion */
  .nested-accordion {
    margin-left: 20px; /* Adjust the indentation as needed */
    border-left: 2px solid #e0e0e0;
  }

  /* Styling for key text */
  .accordion-item strong {
    color: #9e4784; /* Change the key color based on your preference */
    margin-right: 5px;
  }

  /* Styling for value text */
  .accordion-item:not(.nested-accordion) {
    color: #4caf50; /* Change the value color based on your preference */
  }
}
