.navbar {
  width: 100vw;
  backdrop-filter: blur(10px);
  background-color: var(--subdued-pale-blue-200);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px,
    rgba(0, 0, 0, 0.1) 0px -1px 0px inset;

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 20px;
    max-width: 1500px;

    .nav-logo {
      color: var(--primary-light-blue);
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      flex-grow: 1;
    }

    .nav-menu {
      display: flex;
      list-style: none;
      margin-right: 2rem;

      .nav-item {
        line-height: 40px;
        margin-right: 1rem;

        .nav-links {
          color: var(--primary-light-blue);
          font-family: 12px;
          font-weight: 500;
          text-decoration: none;
          padding: 0.2rem 0.5rem;
          height: 100%;
          border-bottom: 3px solid transparent;
          border-left: 2px solid transparent;
          border-right: 2px solid transparent;
          border-top: 2px solid transparent;

          &.active {
            border-radius: 4px;
            color: var(--primary-light-blue);
            border-left: 2px solid var(--primary-light-blue);
            border-right: 2px solid var(--primary-light-blue);
            border-top: 2px solid var(--primary-light-blue);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
              rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
          }
        }

        &:after {
          content: "";
          display: block;
          height: 3px;
          width: 0;
          background: transparent;
          transition: width 0.7s ease, background-color 0.5s ease;
        }

        &:hover:after {
          width: 100%;
          background: var(--primary-light-blue);
        }

        &.active {
          .nav-links {
            color: var(--primary-light-blue);
            border: none;
          }
        }
      }
    }

    .nav-icon {
      display: none;
      font-size: 1.8rem;
      cursor: pointer;
      color: var(--primary-light-blue);
    }

    .profile-menu-icon {
      position: absolute;
      top: -3px;
      right: 20px;
      transform: translate(25%, 50%);
      height: 40px;
      width: 40px;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--unique-color-purple-600);
      color: #fff;
      font-size: 20px;
      font-weight: 900;

      border: 2px solid var(--unique-color-purple-200);
      outline: 2px solid var(--unique-color-purple-600);
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    }

    .profile-menu-wrapper {
      position: absolute;
      top: 40px;
      right: 30px;
      transform: translate(25%, 50%);
      height: max-content;
      width: max-content;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--unique-color-purple-100);
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      opacity: 0;

      &.open {
        opacity: 1;
      }

      .log-out-btn {
        padding: 5px 10px;
        border-radius: 4px;
        border: none;
        background-color: transparent;
        color: #fff;
        cursor: pointer;
        font-size: 12px;
        font-weight: 700;
        transition: all 0.3s ease;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    .nav-container {
      .nav-menu {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;

        .nav-item {
          margin-right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .nav-links {
            width: 50%;
            padding: 0.2rem;
            text-align: center;

            &.active {
              border-bottom: 3px solid var(--primary-light-blue);
            }
          }
        }

        &.active {
          background: var(--subdued-pale-blue-200);
          left: 0px;
          opacity: 1;
          transition: all 0.5s ease;
          z-index: 1;
        }
      }

      .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 30px;
        transform: translate(-100%, 60%);
      }

      .profile-menu-icon {
        position: absolute;
        top: -3px;
        right: 20px;
        transform: translate(25%, 50%);
        height: 40px;
        width: 40px;
        overflow: hidden;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--unique-color-purple-600);
        color: #fff;
      }
    }
  }
}
