.accordion-wrapper {
  max-width: 900px;
  min-width: 900px;

  .accordion-header {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
  }
}
