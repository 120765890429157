.task-data-wrapper {
  width: 100%;
  margin: 10px 0;

  .text {
    font-size: 14px;
    font-weight: 500;
    color: var(--neutral-mid-gray-600);
  }

  .text-description {
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral-mid-gray-600);
    margin: 10px 0;
  }

  .group-chip {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-top: 8px;

    .chip {
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 8px;
      background: #f3f3f3;
      color: rgba($color: #000000, $alpha: 0.7);
      font-weight: 700;
      border: 1px solid #fff;
      outline: 1.5px solid #f3f3f3;

      &.chip-High {
        background: #bc1b06;
        outline: 1.5px solid #bc1b06;
      }

      &.chip-Medium {
        background: #f79009;
        outline: 1.5px solid #f79009;
      }

      &.chip-Low {
        background: #039855;
        outline: 1.5px solid #039855;
      }

      &.chip-date {
        background: #fff4f4;
        outline: 1.5px solid #fff4f4;
      }
    }
  }
}
