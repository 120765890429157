.BmAdHocTask-wrapper {
  width: 100%;
  border-radius: 8px;
  background-color: var(--subdued-pale-blue-200);
  padding: 15px;
  border: 1px solid #e0e0e0;

  .task-details-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    .ant-ribbon-wrapper {
      width: 100%;
    }

    .text-info-wrapper {
      width: 100%;
      border: 1px solid #e0e0e0;
      padding: 20px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;

      .left {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;

        .details {
          font-weight: 700;
          font-size: 16px;

          .title {
            color: var(--neutral-mid-gray);
          }

          .text {
            color: var(--neutral-mid-gray-600);
          }
        }
      }

      .right {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 50px;
        .dead-line-wrapper {
          border-radius: 20px;
          width: max-content;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
          overflow: hidden;
          border: 2px solid var(--unique-color-purple-300);

          .icon {
            margin: 0 5px;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--unique-color-purple-600);
          }

          .text {
            background: var(--unique-color-purple-300);
            font-weight: 700;
            font-size: 14px;
            color: #fff;
            padding: 5px;
          }
        }
      }
    }

    .main-content-wrapper {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: stretch;
      border-radius: 8px;
      gap: 10px;

      .left {
        padding: 20px;
        border: 1px solid #e0e0e0;
        background: #fff;
        border-radius: 8px;
        width: 60%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
        .list-wrapper {
          width: 100%;

          .list-item {
            list-style: none;
            display: flex;
            gap: 12px;
            margin-bottom: 10px;
            border-bottom: #e0e0e0 solid 1px;
            padding-bottom: 15px;
            width: 100%;

            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
            }

            .text {
              font-size: 14px;
              color: var(--neutral-mid-gray-600);
              font-weight: 700;
            }
            .description {
              font-size: 14px;
              color: var(--primary-dark-blue-600);
              font-weight: 500;
              word-break: break-all;
            }
          }
        }
      }

      .right {
        width: 50%;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        background: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .text-icon-wrapper {
          width: 100%;
          padding: 10px 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          .icon {
            font-size: 20px;
            color: var(--cta-danger-red-400);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .text {
            font-size: 16px;
            color: var(--cta-danger-red-400);
            font-weight: 700;
          }
        }

        .text-area-wrapper {
          width: 100%;
          padding: 10px 20px;
        }

        .dropdown-wrapper {
          width: 100%;
          padding: 10px 20px;
        }

        .button-wrapper {
          width: 100%;
          padding: 10px 20px;

          .button {
            background: var(--primary-light-blue-600);
            color: #fff;
          }
        }
      }
    }
  }
}
