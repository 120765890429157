.follow-up-task-wrapper {
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  border: 2px solid var(--primary-light-blue);
  border-radius: 20px;

  .student-personal-details-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    .text {
      font-size: 16px;
      font-weight: 700;
      color: var(--neutral-mid-gray-600);
    }

    .icon {
      font-size: 20px;
      font-weight: 700;
      color: var(--neutral-mid-gray-600);
    }
  }

  .horizontal-line {
    width: 100%;
    margin: 10px auto;
    border: 1px solid var(--neutral-light-gray-100);
  }

  .all-tasks-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    .dot-icon {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: var(--primary-light-blue);
      margin-top: 15px;
      border: 1px solid #ffff;
      outline: 1px solid var(--primary-light-blue);
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
}
