.follow-up-feed-container {
  width: 100%;
  .top-wrapper {
    width: 100%;
    margin-bottom: 15px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 24px;
      color: var(--neutral-mid-gray-600);
    }

    .follow-up-quick-action {
      padding: 10px 20px;
      border: 1px solid var(--primary-light-blue);
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      .left {
        color: var(--neutral-mid-gray-200);
        font-size: 14px;
        font-weight: 700;
      }

      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
      }
    }
  }

  .bottom-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 40px;

    .left-content {
      width: 30%;
    }

    .right-content {
      width: 70%;
    }
  }
}
