.heatmap-container {
  width: 100%;

  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 5px;
}

.gym-display-heatmap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 10px;
}

.heatmap-month-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 50px;
  margin-right: 5px;
}

.heatmap-month {
  display: flex;
  flex-direction: row;
}

.heatmap-week {
  display: flex;
  flex-direction: column;
}

.heat-map-box {
  height: 14px;
  width: 14px;
  border-radius: 2px;
  margin: 2px;
}

.heatmap-work-day-to-show {
  border: 0.5px solid rgb(203, 201, 201);
  cursor: pointer;
  background: #f3f3f3;
}

.heatmap-work-day-to-show[data-level="1"] {
  background: rgba(55, 125, 255, 0.2);
}

.heatmap-work-day-to-show[data-level="2"] {
  background: rgba(55, 125, 255, 0.4);
}

.heatmap-work-day-to-show[data-level="3"] {
  background: rgba(55, 125, 255, 0.6);
}

.heatmap-work-day-to-show[data-level="4"] {
  background: rgba(55, 125, 255, 0.8);
}

.heatmap-work-day-to-show[data-level="5"] {
  background: rgba(55, 125, 255, 1);
}

.legends-display[data-level="0"] {
  background: #f3f3f3;
}

.legends-display[data-level="1"] {
  background: rgba(55, 125, 255, 0.2);
}

.legends-display[data-level="2"] {
  background: rgba(55, 125, 255, 0.4);
}

.legends-display[data-level="3"] {
  background: rgba(55, 125, 255, 0.6);
}

.legends-display[data-level="4"] {
  background: rgba(55, 125, 255, 0.8);
}

.legends-display[data-level="5"] {
  background: rgba(55, 125, 255, 1);
}

.heatmap-empty-day-no-show {
  color: white;
  background: transparent;
}

.heatmap-day {
  font-size: 12px;
  font-weight: 500;
  position: relative;
  color: #505862;
  text-transform: capitalize;
}

.heat-map-only-days {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin: 0;
  gap: 4px;
  padding-right: 5px;
}

.heatmap-month-dislpay {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #505862;
}

.legens-box {
  background: red;
}

.legends-display-container-outer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
}

.legends-display {
  height: 15px;
  width: 15px;
  margin-bottom: 2px;
  border: 0.5px solid rgb(203, 201, 201);
  border-radius: 2px;
}

.legends-display-text {
  font-size: 12px;
  font-weight: 500;
  margin: 3px 0;
  color: #505862;
  width: max-content;
  text-transform: capitalize;
}

.tooltip-container {
  background: rgb(50, 106, 151);
  position: absolute;
}

.heatmap-no-show-day {
  color: white;
  pointer-events: none;
}

.lightgrey-day {
  color: #8c9aac;
}

.course-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 0px;

  .latest-module {
    display: flex;
    align-items: stretch;
    gap: 12px;

    .card {
      display: flex;
      padding: 16px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      border-radius: 20px;
      background: #f9f9f9;

      div:nth-child(1) {
        color: #676767;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
      }

      div:nth-child(2) {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }

  .gym-details {
    display: flex;
    align-items: center;
    gap: 12px;

    .cards {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 12px;

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        padding: 16px 24px;
        background: #f9f9f9;
        border-radius: 20px;
        text-align: center;

        div:nth-child(1) {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        div:nth-child(2) {
          color: #676767;
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
        }

        .icon {
          font-weight: 600;
          font-size: 12px;
          padding: 8px 10px;
          border-radius: 50%;
        }
      }
    }
  }
}

.modal-contest-content-wrapper {
  .rows {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    label {
      color: #2666be;
      font-size: 20px;
      font-weight: 600;
      flex: 1;
    }

    span {
      flex: 1;
    }
  }

  .cards {
    display: flex;
    align-items: center;
    margin-top: 24px;

    flex-wrap: wrap;
    gap: 12px;

    .card {
      border-radius: 20px;
      background: #2666be;
      padding: 16px;
      width: 250px;
      height: 100px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div:nth-child(2) {
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }
}
