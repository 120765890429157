.follow-up-placement-details {
  .job-drives-overview {
    border-radius: 20px;
    background: #fafcff;
    padding: 16px 12px;

    .header {
      color: #000;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      line-height: 30px;
    }

    .click-to-view {
      color: #797979;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      line-height: 30px;
    }
  }

  .cards {
    margin: 12px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;

    .card {
      min-width: 200px;
      min-height: 125px;
      box-shadow: 1px 1px 6px 0px rgba(38, 102, 190, 0.25);
      border-radius: 20px;
      border: 1px solid #2666be;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .value {
        color: #2666be;
        text-align: center;
        font-size: 55px;
        font-weight: 500;
      }

      .title {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}

.resume-feedback-container {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  gap: 12px;
  width: 100%;

  iframe {
    width: 100%;
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    textarea {
      flex: 1;
    }

    .buttons {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;
    }

    .blue {
      color: #2666be;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px; /* 150% */
    }

    .resume-checklist {
      max-height: 50%;
      overflow: auto;
      border-radius: 8px;
      background: #ededed;
      padding: 16px;

      ul {
        padding-left: 20px;
      }
    }
  }
}
