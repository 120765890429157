.student-personal-details-wrapper {
  width: 100%;
  padding: 15px;
  border: 2px solid var(--primary-light-blue);
  border-radius: 20px;

  .student-personal-details-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    .text {
      font-size: 16px;
      font-weight: 700;
      color: var(--neutral-mid-gray-600);
    }

    .icon {
      font-size: 20px;
      font-weight: 700;
      color: var(--neutral-mid-gray-600);
    }
  }

  .horizontal-line {
    width: 100%;
    margin: 10px auto;
    border: 1px solid var(--neutral-light-gray-100);
  }

  .wrapper-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    .data-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 2fr 5fr;
      gap: 5px;

      .text {
        font-size: 14px;
        font-weight: 700;
        color: var(--primary-light-blue-600);
      }
      .data {
        font-size: 14px;
        font-weight: 700;
        color: var(--neutral-mid-gray);
      }
    }
  }
}
