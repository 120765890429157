.all-activity-wrapper {
  width: 100%;

  .activity-wrapper {
    border-radius: 20px;
    margin-bottom: 15px;
    background: #f4f4f4;
    box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    padding: 20px;
    // border: 2px solid var(--primary-light-blue);
    border-radius: 20px;
    display: flex;
    // grid-template-columns: 2fr 8fr;
    gap: 10px;
    overflow: hidden;

    .activity-data-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
    }
    .follow-up-note-wrapper {
      overflow: scroll;
      flex: 1;
      padding: 15px;
      border-radius: 20px;
      background: #fff;
      box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      min-height: 300px;
      max-height: 500px;

      .follow-up-note-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
          color: #2666be;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .follow-up-essentials-wrapper {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 15px;
  }

  .all-info-box-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 15px;
    .info-box-wrapper {
      background: var(--subdued-pale-blue-100);
      filter: opacity(0.9);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
  }

  .taking-pointer-wrapper {
    background: #f4f4f4;
    box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 15px;
    border-radius: 15px;
    .text {
      font-size: 16px;
      font-weight: 700;
      color: var(--neutral-mid-gray-600);
    }
    .data-wrapper {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
    }
  }
}
