.info-box-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  border-radius: 20px;
  background: #fff;
  padding: 20px;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.1);

  .title {
    color: #2666be;
    font-size: 14px;
    font-weight: 600;
  }

  .data {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
}
