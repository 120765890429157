.home-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

  .title-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;

    .text {
      font-size: 24px;
      font-weight: 600;
      color: var(--neutral-mid-gray-600);
    }

    .name {
      font-size: 24px;
      font-weight: 900;
      color: var(--neutral-mid-gray-600);
    }
  }

  .sub-text {
    font-size: 16px;
    font-weight: 400;
    color: var(--neutral-mid-gray-400);
  }
  .tasks-grid-wrapper {
    .crm-ant-text-com {
      font-size: 12px;
      font-weight: inherit;
      color: inherit;
      margin-left: 8px;
    }
  }

  .task-view {
    width: 100%;
  }
}
