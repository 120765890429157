.data-grid-wrapper {
  width: calc(100vw - 40px);
  margin: 0 auto;
  overflow-x: auto;

  .hidden-column{
    display: none !important;
  }

  .MuiDataGrid-toolbarContainer {
    .MuiButton-text {
      color: var(--cta-success-green);
      font-weight: 700;
    }
  }

  .MuiDataGrid-columnHeaderTitle {
    color: var(--unique-color-orange-600);
    font-weight: 700;
  }

  .MuiDataGrid-cell:focus {
    outline: 1px solid transparent !important;
  }

  .MuiDataGrid-columnHeader {
    border: 1px solid #e0e0e0;
    border-right: none;
    border-bottom: none;

    &:first-child{
      border-left: none; 
    }
  }

  .MuiDataGrid-cell {
    border-right: 1px solid #e0e0e0;
  }

  .MuiDataGrid-row {
    .MuiDataGrid-cell {
      color: var(--primary-dark-blue-300);
      font-weight: 700;
    }
    &.Mui-selected {
      .MuiDataGrid-cell {
        color: var(--unique-color-purple);
        font-weight: 700;
      }
    }
  }

  .MuiDataGrid-footerContainer {
    .MuiTablePagination-root {
      color: var(--cta-warning-orange-600);
      font-size: 16px;
      font-weight: 700 !important;

      .MuiTablePagination-actions {
        .MuiButtonBase-root {
          color: var(--cta-warning-orange-600);
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    .MuiDataGrid-selectedRowCount {
      opacity: 0;
    }
  }
}
