* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  /* Primary Brand Colors */
  --primary-dark-blue: rgba(19, 51, 95, 1);
  --primary-dark-blue-100: rgba(30, 75, 126, 1);
  --primary-dark-blue-200: rgba(41, 78, 121, 1);
  --primary-dark-blue-300: rgba(12, 30, 54, 1);
  --primary-dark-blue-400: rgba(9, 25, 39, 1);
  --primary-dark-blue-600: rgba(7, 20, 35, 1);

  --primary-light-blue: rgba(46, 128, 236, 1);
  --primary-light-blue-100: rgba(64, 140, 230, 1);
  --primary-light-blue-200: rgba(28, 98, 181, 1);
  --primary-light-blue-300: rgba(102, 168, 255, 1);
  --primary-light-blue-400: rgba(28, 79, 170, 1);
  --primary-light-blue-600: rgba(22, 81, 142, 1);

  --primary-accent-yellow: rgba(255, 221, 64, 1);
  --primary-accent-yellow-100: rgba(255, 211, 80, 1);
  --primary-accent-yellow-200: rgba(255, 229, 143, 1);
  --primary-accent-yellow-300: rgba(230, 184, 0, 1);
  --primary-accent-yellow-400: rgba(210, 163, 0, 1);

  --cta-success-green: #4caf50;
  --cta-success-green-100: #5db762;
  --cta-success-green-200: #7ecb79;
  --cta-success-green-300: #388e3e;
  --cta-success-green-400: #2d7734;
  --cta-success-green-600: #286b30;

  --cta-danger-red: #ff5733;
  --cta-danger-red-100: #ff6b4d;
  --cta-danger-red-200: #ff825e;
  --cta-danger-red-300: #e64124;
  --cta-danger-red-400: #d73a20;
  --cta-danger-red-600: #b5301d;

  --cta-warning-orange: #ffa500;
  --cta-warning-orange-100: #ffb720;
  --cta-warning-orange-200: #ffc840;
  --cta-warning-orange-300: #e69200;
  --cta-warning-orange-400: #d68900;
  --cta-warning-orange-600: #c07d00;

  /* Neutral Colors */
  --neutral-mid-gray: #808080;
  --neutral-mid-gray-100: #a0a0a0;
  --neutral-mid-gray-200: #606060;
  --neutral-mid-gray-300: #999999;
  --neutral-mid-gray-400: #737373;
  --neutral-mid-gray-600: #4d4d4d;

  --neutral-light-gray: #d3d3d3;
  --neutral-light-gray-100: #e0e0e0;
  --neutral-light-gray-200: #c0c0c0;
  --neutral-light-gray-300: #bbbbbb;
  --neutral-light-gray-400: #cccccc;
  --neutral-light-gray-600: #a6a6a6;

  --subdued-pale-blue: #cce0ff;
  --subdued-pale-blue-100: #ddeaff;
  --subdued-pale-blue-200: #e7f0ff;
  --subdued-pale-blue-300: #b2cfff;
  --subdued-pale-blue-400: #99b6f5;
  --subdued-pale-blue-600: #7392c6;

  --subdued-pale-green: #b7e4c7;
  --subdued-pale-green-100: #c9edd8;
  --subdued-pale-green-200: #daf6e9;
  --subdued-pale-green-300: #9ad1b0;
  --subdued-pale-green-400: #8ac9a3;
  --subdued-pale-green-600: #69b48b;

  --subdued-pale-peach: #ffc4b3;
  --subdued-pale-peach-100: #ffd0c0;
  --subdued-pale-peach-200: #ffe1d8;
  --subdued-pale-peach-300: #ffa196;
  --subdued-pale-peach-400: #ff917f;
  --subdued-pale-peach-600: #e68a75;

  --unique-color-purple: #7e57c2;
  --unique-color-purple-100: #8e68cf;
  --unique-color-purple-200: #9f79db;
  --unique-color-purple-300: #6b47b3;
  --unique-color-purple-400: #6241a6;
  --unique-color-purple-600: #563a8d;

  --unique-color-green: #4caf50;
  --unique-color-green-100: #5db762;
  --unique-color-green-200: #7ecb79;
  --unique-color-green-300: #388e3e;
  --unique-color-green-400: #2d7734;
  --unique-color-green-600: #286b30;

  --unique-color-orange: #ff9800;
  --unique-color-orange-100: #ffa720;
  --unique-color-orange-200: #ffb840;
  --unique-color-orange-300: #e67200;
  --unique-color-orange-400: #d86900;
  --unique-color-orange-600: #bf5c00;

  --unique-color-pink: #e91e63;
  --unique-color-pink-100: #ea2f6e;
  --unique-color-pink-200: #eb407a;
  --unique-color-pink-300: #d01758;
  --unique-color-pink-400: #c41652;
  --unique-color-pink-600: #a11345;

  /* Different Shades */

  --shade-light: rgba(255, 255, 255, 0.5);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-wrapper {
  width: 100vw;
  height: calc(100vh - 80px);
}

.page-container {
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.table {
  th,
  td {
    border: 1px solid black;
    border-radius: 10px;
    padding: 2px 8px;
  }
  thead {
    tr {
      background-color: var(--neutral-mid-gray-100);
      th {
        padding: 10px 5px;
        text-align: left;
        font-size: 14px;
        border-style: groove;
        // border-bottom: 1px solid var(--neutral-mid-gray-100);
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px 5px;
        font-size: 14px;
        border-style: groove;
        // border-bottom: 1px solid var(--neutral-mid-gray-100);
      }
    }
  }
}

.md-editor {
  height: 100%;
  border-radius: 10px !important;
}

.ant-btn-default {
  background-color: #2666be;
  color: white;
}
